// import './main.js';
import $ from 'jquery';
import 'bootstrap'; // required for using bootstrap events
import {firebase} from './firebase';
import { serverTimestamp } from 'firebase/firestore';

//document.addEventListener('DOMContentLoaded',
$(function(){
    const modal = $('#modal-contact');
    const form = modal.find('#contact-form');
    const formType = form.data('type');

    // console.log(formType);
    // console.log(formType === 'covid-clear');

    function resetForm()
    {
        form.removeClass("was-validated");
        form.find('#contact-form-name').val('');
        form.find('#contact-form-email').val('');
        form.find('#contact-form-subject').val('');
        form.find('#contact-form-body').val('');

        if(formType === 'covid-clear')
        {
            form.find('#contact-form-phone').val('');
            form.find('#contact-form-position').val('');
            form.find('#contact-form-company').val('');
        }

        form.show();
        modal.find('#contact-form-success').hide();
    }

    function handleContactSubmit(event){
        // const form = $(this);
        event.preventDefault();
        event.stopPropagation();

        let status = form[0].checkValidity();
        if(status)
        {
            /*
            contact object {
                name: string;
                email: string;
                subject: string;
                message: string;
                date: Date;
                // covid clear has the additional items hacked in
                company?: string;
                phone?: string;
                position?: string;   
            }
            */

            const formData = new FormData(form[0]);
            const contactDetails = {
                name: formData.get('name'),
                email: formData.get('email'),
                subject: formData.get('subject'),
                message: formData.get('message'),
                date: serverTimestamp(),
            };
            if(formType === 'covid-clear') {
                contactDetails.subject = 'COVID_CLEAR';
                contactDetails.company = formData.get('company');
                contactDetails.phone = formData.get('phone');
                contactDetails.position = formData.get('position');
            }
            // console.log(JSON.stringify(contactDetails));
            const collection = firebase.firestore().collection('contactForms');
            collection.add(contactDetails).then((doc) => {
                console.log(doc);
                form.hide(400);
                modal.find('#contact-form-success').show(400);
        }).catch(()=>{
            console.log('contact failed');
        });


            // $.post('submit', formData, (reply) => {
            //     if(reply.success)
            //     {
            //         form.hide(400);
            //         modal.find('#contact-form-success').show(400);
            //     }
            // }, 'json');
        }
        else{
            form.addClass("was-validated");
        }
        return false;
    };

    modal.on('hidden.bs.modal', (event) => {
        resetForm();
    });

    modal.on('shown.bs.modal', (event) => {
        form.find('#contact-form-name').focus();
    });

    if(form)
    {
        form.on('submit', handleContactSubmit );
    }
});

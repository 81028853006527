// Your web app's Firebase configuration
export const firebaseConfig = {
    apiKey: "AIzaSyAFsZyFF_ovhMdiX3tezzI8b_fgSB2WJM8",
    authDomain: "medicos-website.firebaseapp.com",
    databaseURL: "https://medicos-website.firebaseio.com",
    projectId: "medicos-website",
    storageBucket: "medicos-website.appspot.com",
    messagingSenderId: "145018040331",
    appId: "1:145018040331:web:0190c718b1e1fa8226170e"
};

